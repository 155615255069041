body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.taskCardTodo {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(241, 251, 255, 1) 0%,
    rgba(228, 241, 255, 0.5) 100%
  );
}

.taskCardCompleted {
  background: rgba(255, 255, 255, 0);
}

.ant-badge-count {
  z-index: 999 !important;
}

.ant-card,
.ant-table {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.09);
}

.ant-card .ant-table {
  box-shadow: none;
}

.clickable-statistic {
  transition: 0.3 ease;
  cursor: pointer;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
}

.clickable-statistic:hover {
  border-bottom: 2px solid #1890ff;
}
div.ant-upload {
  width: 100%;
}

.vertical-radio-group .ant-radio-wrapper {
  display: block;
}
.editable-cell {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  padding: 10px;
}
.editable-cell:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1);
}
.editable-cell:active {
  transform: scale(1.02);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #d9d9d9;
}

.table-striped .ant-row:nth-child(odd) {
  background-color: #f9f9f9;
}
.table-striped .ant-row .ant-form-item,
.table-striped .ant-row .ant-row.ant-form-item-row {
  background-color: inherit !important;
}

.vertical-radio-group .ant-radio-wrapper {
  display: block;
}
.table-cell-editable {
  position: relative;
  cursor: pointer;
}

.table-cell-editable .edit-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #1890ff;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.table-cell-editable:hover .edit-icon {
  opacity: 1;
}
