.App {
  text-align: center;
}

.ant-descriptions-item-content {
  direction: ltr !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.ant-menu-inline-collapsed-tooltip {
  display: none !important;
}

.ant-menu-title-content {
  width: 4px !important;
}

.ant-menu.ant-menu-sub.ant-menu-vertical {
  border-radius: 8px;
  background: #061e36;
}

.ant-layout-sider {
  width: 50px !important;
  min-width: 50px !important;
  max-width: 50px !important;
}

h1 {
  font-size: 24px !important;
}

.ant-form-item-label {
  font-weight: 500 !important;
}

.filterForm .ant-form-item-label {
  font-weight: normal !important;
}

.ant-card,
.ant-picker-panel-container,
.ant-modal-content {
  border-radius: 8px !important;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 5px !important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 5px !important;
}

.ant-modal-header {
  border-radius: 8px 8px 0 0 !important;
}

.ant-picker,
.ant-select-selector,
.ant-select,
.ant-input,
.ant-btn,
.ant-select-selection-search,
.ant-select-dropdown,
.ant-popover-inner,
.ant-notification-notice,
.ant-input-affix-wrapper,
.ant-form-item-control-input-content > input {
  border-radius: 5px !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.steps-content {
  width: 100%;
  min-height: 180px;
}

.steps-action {
  margin-top: 24px;
}

.table-cell {
  height: 100%;
  display: table-cell;
  padding: 0px !important;
  margin: 0px !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-menu-item.ant-menu-item-active {
  background: #1f3952 !important;
}

.ant-menu-item.ant-menu-item-selected {
  background: #1f3952 !important;
}

.localhost .ant-layout-sider,
.localhost .ant-menu-dark {
  background: #110636 !important;
}

table tr:nth-child(2n) td {
  background-color: #f8f8f8 !important;
}

table th {
  background-color: #eeeeee !important;
}

/* Table td with "..." */
table {
  table-layout: fixed !important;
}

/* Table td with "..." */
td {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

#communication_table table td {
    white-space: pre-wrap !important;
}

.ant-pagination-total-text {
  position: absolute;
  left: 0;
}

.table-text-small table, .table-text-small {
  font-size: 12px;
}

.radio-style-checkbox .ant-checkbox-inner {
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.radio-style-checkbox .ant-checkbox-inner::after {
  border-radius: 50%;
}

.radio-style-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}

.grouped-row, .grouped-row td {
  border-top: 1px solid #d7d7d7;
}

.table-label {
    display: inline-block;
    background-color: #cce4ff;
    color: #0066cc;
    border-radius: 4px;
    font-weight: bold;
    text-align: center;
    float: right;
    max-width: 70px;
    white-space: break-spaces;
    font-size: 9px;
    margin-top: -5px;
    margin-bottom: -5px;
}

.table-label.green {
    background-color: #ccff66!important;
    color: #006633!important;
    width: 70px;
    height: 22px;
    align-items: center;
    display: grid;
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 0;
}

.mb-0 {
    margin-bottom: 0 !important;
}

/* PartnerCustomCalendar.css */
.week-calendar {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
}

.week-calendar .calendar-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
    background-color: #eeeeee !important;
    height: 25px;
}

.week-calendar .day-column-header {
    flex: 1;
    text-align: end;
    font-weight: 400;
    height: auto;
    padding: 0 12px 5px 0;
    line-height: 18px;
}

.week-calendar .time-column-header {
    align-items: flex-end;
    width: 105px;
    height: 42px;
}

.week-calendar .calendar-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 600px; /* Adjust as needed */
}

.week-calendar .time-column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 105px;
}

.week-calendar .event-list {
    width: 150px; /* Adjust the width as needed */
    padding: 10px;
}

.week-calendar .event {
    background-color: #f0f0f0;
    margin: 5px 0;
    padding: 5px;
}

.week-calendar .time-cell {
    padding-right: 5px;
    height: 20px;
    width: 105px;
    text-align: end;
    font-weight: 400;
}

.week-calendar .day-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow-x: auto;
    max-width: 100%;
}

.week-calendar .time-slot {
    height: 20px; /* Adjust height to match time-cell */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-top: 1px solid #f0f0f0;
    border-radius: 0;
    transition: background 0.3s;
    border-left: 1px solid #f0f0f0;
}

.week-calendar .time-cell:first-child,
.week-calendar .time-slot:first-child {
    min-height: 100px;
    text-align: end;
    font-weight: 400;
    padding-top: 20px;
}

.week-calendar .event {
    background-color: #f0f0f0;
    font-weight: bold;
}

.week-calendar .today {
    color: #1890ff;
    background: rgb(230, 247, 255);
    border-top: 2px solid #1890ff;
}

.week-calendar .odd {
    transition: color 0.3s;
    background-color: #f8f8f8;
}

.week-calendar .green {
    background-color: #d8ecd4;
    border:none;
}

.week-calendar .red {
    background-color: #ffcccc!important;
    border:none;
}

.week-calendar .scroll {
    overflow-x: scroll;
    display: inline-block;
}

.week-calendar .middle {
    padding-bottom: 30px;
    margin-top: -10px;
}
.mb-0 {
    margin-bottom: 0 !important;
}

.building-details .ant-form-item {
    margin-top: 10px !important;
    margin-bottom: 14px !important;
}

.btn-status-danger:hover, .btn-status-danger:focus {
    color: #cf1322 !important;
    border-color: #ffa39e !important;
    background: #fff1f0 !important;
}
.btn-status-warning:hover, .btn-status-warning:focus {
    color: #d46b08 !important;
    border-color: #ffd591 !important;
    background: #fff7e6 !important;
}
.btn-status-success:hover, .btn-status-success:focus {
    color: #389e0d !important;
    border-color: #b7eb8f !important;
    background: #f6ffed !important;
}